import React, { useMemo } from 'react';
import {
  SizeVariantProp,
  ThemeColorProp,
  getTextColor,
  getTextSize,
} from '../style';
import { useEhState } from '@horos/eh-react-hooks';
import { STheme } from '../state/theme.state';
import styled from 'styled-components/native';

interface CLabelStyleProps {
  bold?: boolean;
  fixedWidth?: number;
}

const CLabelInternal = styled.Text<
  {
    color: string;
    fontSize: number;
  } & CLabelStyleProps
>`
  color: ${p => p.color};
  font-size: ${p => p.fontSize}px;
  font-weight: ${p => (p.bold ? 600 : 400)};

  ${p => (p.fixedWidth ? `width:${p.fixedWidth}px` : '')}
`;

export function CLabel({
  text,
  size = 'm',
  variant = 'main',
  themeColor,
  children,
  bold,
  fixedWidth,
}: {
  text?: string;
  children?: React.ReactNode;
} & CLabelStyleProps &
  SizeVariantProp &
  ThemeColorProp) {
  const { stheme } = useEhState(STheme);

  const color = useMemo(() => {
    if (themeColor) return stheme[themeColor];
    if (variant) return getTextColor(variant, stheme);

    return getTextColor('main', stheme);
  }, [variant, themeColor, stheme]);

  return (
    <CLabelInternal
      fixedWidth={fixedWidth}
      numberOfLines={1}
      color={color}
      fontSize={getTextSize(size)}
      bold={Boolean(bold)}>
      {text || children}
    </CLabelInternal>
  );
}
