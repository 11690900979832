import { EhState } from '@horos/eh';
import { DefaultTheme } from '../style/themes.style';
import { ColorSchemeName } from 'react-native';
import { ITheme } from '../style';

export const SDeviceTheme = EhState.fromInitialState<{
  deviceTheme: ColorSchemeName;
}>({ deviceTheme: 'dark' });

export type SThemeType = { stheme: ITheme; userSelection?: boolean };
export const STheme = EhState.fromInitialState<SThemeType>({
  stheme: DefaultTheme,
  userSelection: false,
});

// SDeviceTheme.register(({ deviceTheme }) => {
//   STheme.fire({ stheme: deviceTheme === 'light' ? LightTheme : DarkTheme });
// });
