import React from 'react';
import { EhState } from '@horos/eh';
import { useEhState } from '@horos/eh-react-hooks';
import { ComponentType } from 'react';
import { StyledComponent } from 'styled-components';

type CombineObjects<T extends unknown[]> = T extends [infer U, ...infer Rest]
  ? U extends object
    ? U & CombineObjects<Rest>
    : never
  : {};

export function StyledWithEhs2<
  S extends object[],
  P extends object,
  NC extends ComponentType<any>,
>(
  Comp: StyledComponent<NC, P>,
  ...ehStates: { [K in keyof S]: EhState<S[K]> }
) {
  const NewComp = Comp as ComponentType<P & CombineObjects<S>>;
  return (props: P) => {
    const combinedState = {} as CombineObjects<S>;
    for (const ehState of ehStates) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const s = useEhState(ehState);
      Object.assign(combinedState, s);
    }
    return <NewComp {...props} {...combinedState} />;
  };
}

// export function StyledWithEhs<
//   S extends object[],
//   P extends object,
//   ThemeT extends object, // Assuming ThemeT is the type for theming, used with styled-components
//   NC extends ComponentType<P>,
// >(
//   Comp: StyledComponent<NC, ThemeT, P, never>,
//   ...ehStates: { [K in keyof S]: EhState<S[K]> }
// ) {
//   // const NewComp = Comp as ComponentType<P & CombineObjects<S>>;
//   return (props: P & CombineObjects<S>) => {
//     const combinedState = {} as CombineObjects<S>;
//     for (const ehState of ehStates) {
//       // eslint-disable-next-line react-hooks/rules-of-hooks
//       const s = useEhState(ehState);
//       Object.assign(combinedState, s);
//     }
//     return <Comp {...props} {...combinedState} />;
//   };
// }

// export function fuckThis<
//   C extends ComponentType<any>,
//   T extends object,
//   P extends object,
//   S extends object[],
// >(
//   f: ReactNativeThemedStyledFunction<C, T>,
//   ...ehStates: { [K in keyof S]: EhState<S[K]> }
// ) {

// }

export function StyledWithEhs<
  CO extends React.ComponentType<any>,
  C extends StyledComponent<CO, any, any, any>,
  S extends object[],
>(Comp: C, ...ehStates: { [K in keyof S]: EhState<S[K]> }) {
  const f = (props: any) => {
    const combinedState = {} as CombineObjects<S>;
    for (const ehState of ehStates) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const s = useEhState(ehState);
      Object.assign(combinedState, s);
    }
    return <Comp {...props} {...combinedState} />;
  };

  return f as unknown as CO;
}

// export function StyledWithEhs<
//   A extends ComponentType<any>,
//   B extends object,
//   C extends object,
//   S extends object[],
// >(
//   Comp: StyledComponent<A, B, C>,
//   ...ehStates: { [K in keyof S]: EhState<S[K]> }
// ) {
//   return (shit: StyledComponentProps<A, B, C, any, any>) => {
//     return <Comp {...shit} />;
//   };
// }

// export function StyledWithEhs<
//   P extends object,
//   ThemeT extends object,
//   RealComp extends React.ComponentType<P>,
// >(Comp: StyledComponent<RealComp, ThemeT, P>) {
//   return (props: P) => {
//     return <Comp {...props} />;
//   };
// }
