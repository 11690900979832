import React, { ReactNode } from 'react';
import { CPageContainer, CTitle } from './common-components';
import { MarginHM } from './layout';
import { CHeader } from './CHeader';

type CPageProps = {
  children: ReactNode;
  noHeader?: boolean;
  title: string;
};

export function CPage({ children, noHeader = false, title }: CPageProps) {
  return (
    <CPageContainer>
      {!noHeader && <CHeader />}
      {title && (
        <>
          <MarginHM />
          <CTitle>{title}</CTitle>
        </>
      )}
      {children}
    </CPageContainer>
  );
}
