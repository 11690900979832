import * as React from 'react';
import {
  NavigationContainerRef,
  useNavigation,
} from '@react-navigation/native';

export type Routes = {
  home: number;
  join: number;
  create: number;
  waitingRoom: number;
  game: number;
  afterGame: number;
};

export const navigationRef = React.createRef<NavigationContainerRef<Routes>>();

export function useNav() {
  return useNavigation<Routes>();
}
