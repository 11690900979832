import axios from 'axios';
import { IEvent } from '../../models';
import { WebSocketClient } from '../websocket.service';

export interface IDevice {
  init(
    url: string,
    gameId: string,
    playerId: string,
    messageCallback: (event: IEvent) => void,
    errorCallback: (err: any) => void,
  ): void;

  send(message: string): void;
}

export class WS implements IDevice {
  client?: WebSocketClient;

  init(
    baseUrl: string,
    gameId: string,
    playerId: string,
    messageCallback: (event: IEvent) => void,
    errorCallback: (err: any) => void,
  ): void {
    const url = baseUrl + '/game/' + gameId + '/' + playerId;
    this.client = new WebSocketClient(url);
    this.client.onMessage(e => {
      const parsed = JSON.parse(e.data) as IEvent;
      messageCallback(parsed);
    });

    this.client.onError(errorCallback);
  }

  send(message: string): void {
    this.client?.send(message);
  }
}

export class HttpDevice implements IDevice {
  url = '';

  init(
    url: string,
    gameId: string,
    playerId: string,
    messageCallback: (event: IEvent) => void,
    errorCallback?: (err: any) => void,
  ): void {
    this.url = url + '/game-poll/' + gameId + '/' + playerId;
    //axios.get(url, { responseType: 'stream' }).then(response => {
    axios({
      method: 'get',
      url: this.url,
      responseType: 'stream', // Set response type to stream for receiving chunks
    })
      .then(response => {
        let buffer = ''; // Buffer to store incomplete messages

        response.data.on('data', (chunk: Buffer) => {
          buffer += chunk.toString(); // Convert buffer to string

          // Split by the delimiter "\n\n" to separate messages
          let messages = buffer.split('\n\n');
          buffer = messages.pop() || ''; // Keep the last incomplete message in the buffer

          // Process each complete message
          messages.forEach(message => {
            if (message.trim()) {
              // If needed, you can parse JSON or handle data differently
              const e = JSON.parse(message) as IEvent;
              messageCallback(e);
            }
          });
        });

        response.data.on('end', () => {
          console.log('Stream has ended.');
        });

        response.data.on('error', (err: any) => {
          if (errorCallback) {
            errorCallback(err);
          } else {
            console.error('Error receiving stream data:', err);
          }
        });
      })
      .catch(err => {
        console.error('Error making GET request:', err);
        if (errorCallback) {
          errorCallback(err);
        }
      });
  }

  send(message: string): void {
    axios.post(this.url + '/game-event', message);
  }
}
