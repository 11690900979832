import React from 'react';
import { CPageContainer, CBgImgVContainer } from '../components';
import { MarginHL, MarginHS } from '../components/layout';
import { navigationRef } from '../state/navigation.state';
import { CButton } from '../components/CButton';
import styled from 'styled-components/native';

const BackgroundPng = require('../assets/background.png');

const HomeTitle = styled.Text`
  font-size: 56px;
  font-weight: 400;
  font-family: Kolker Brush;
  color: white;
`;

const HomeSecondaryTitle = styled.Text`
  font-size: 32px;
  font-weight: 400;
  font-family: Josefin Sans;
  color: white;
`;

export function Home() {
  return (
    <CPageContainer>
      <CBgImgVContainer resizeMode="cover" source={BackgroundPng}>
        <HomeTitle>IMPOSTER</HomeTitle>
        <HomeSecondaryTitle>games</HomeSecondaryTitle>
        <MarginHL />
        <MarginHL />
        <CButton
          size="l"
          text="Join game"
          variant="main"
          onClick={() => navigationRef.current?.navigate('join', 0)}
        />
        <MarginHS />
        <CButton
          size="l"
          text="Create game"
          variant="secondary"
          onClick={() => navigationRef.current?.navigate('create', 0)}
        />

        {/* <IconButton
          icon={faCodeMerge}
          text="join"
          onClick={() => navigationRef.current?.navigate('join', 0)}
        />
        <MarginWL />
        <IconButton
          icon={faPen}
          text="create"
          onClick={() => navigationRef.current?.navigate('create', 0)}
        /> */}
      </CBgImgVContainer>
    </CPageContainer>
  );
}
