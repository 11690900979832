import { EhState } from '@horos/eh';
import { Dimensions } from 'react-native';

type PercentageOfDimFunc = (
  percentage: number,
  min?: number,
  max?: number,
  numeric?: boolean,
) => string | number;

type RangeForDimArg = {
  value: number;
  dim_range_max: number;
  dim_range_min: number;
};

type RangeForDimFunc = (
  defaultValue: number,
  ...ranges: RangeForDimArg[]
) => string | number;

export interface IDimensionsLogic {
  height: number;
  width: number;
  is_portrait: boolean;

  pw: PercentageOfDimFunc;
  ph: PercentageOfDimFunc;

  rw: RangeForDimFunc;
  rh: RangeForDimFunc;
}

export type SDimensionsType = { dims: IDimensionsLogic };

// const makeDimensionsState: () => SDimensionsType = () => {
//     const { height, width } = Dimensions.get('window');
//     const dimensions =
//   return {
//     height,
//     width,
//       is_portrait: height > width,
//     pw(    percentage: number,
//     min: number = -1,
//     max: number = -1,
//         numeric: boolean = false) {

//     }
//   };
// };

class SDimensionsStateLogic implements IDimensionsLogic {
  is_portrait: boolean;

  constructor(public height: number = 0, public width: number = 0) {
    if (!height || !width) {
      const dims = Dimensions.get('window');
      this.height = dims.height;
      this.width = dims.width;
    }
    this.is_portrait = this.height > this.width;
  }

  pw(
    percentage: number,
    min: number = -1,
    max: number = -1,
    numeric: boolean = false,
  ) {
    return percentageDimension(percentage, this.width, min, max, numeric);
  }
  ph(
    percentage: number,
    min: number = -1,
    max: number = -1,
    numeric: boolean = false,
  ) {
    return percentageDimension(percentage, this.height, min, max, numeric);
  }

  rw(defaultValue: number, ...ranges: RangeForDimArg[]) {
    return rangeForDimension(this.width, defaultValue, true, ...ranges);
  }

  rh(defaultValue: number, ...ranges: RangeForDimArg[]) {
    return rangeForDimension(this.height, defaultValue, true, ...ranges);
  }
}

function percentageDimension(
  percentage: number,
  dim: number,
  min: number,
  max: number,
  numeric = false,
) {
  let res = (percentage * dim) / 100;
  if (min !== -1) res = Math.max(min, res);
  if (max !== -1) res = Math.min(max, res);
  if (numeric) return res;
  return `${res}px`;
}

function rangeForDimension(
  dim: number,
  defaultValue: number,
  numeric: boolean,
  ...ranges: RangeForDimArg[]
) {
  for (const range of ranges) {
    if (dim < range.dim_range_min || dim > range.dim_range_max) continue;
    return numeric ? range.value : `${range.value}px`;
  }

  return numeric ? defaultValue : `${defaultValue}px`;
}

const SDeviceDimensions = EhState.fromInitialState(Dimensions.get('window'));

const makeStateInstance = (height = 0, width = 0) => ({
  dims: new SDimensionsStateLogic(height, width),
});

export const SDimensions = EhState.fromInitialState(makeStateInstance());

SDeviceDimensions.register(({ height, width }) => {
  SDimensions.fire(makeStateInstance(height, width));
});

Dimensions.addEventListener('change', ({ window }) => {
  SDeviceDimensions.fire(window);
});
