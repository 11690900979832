import React from 'react';
import { StyledWithEhs } from './styledWithEh';
import styled from 'styled-components/native';
import { STheme, SThemeType } from '../state/theme.state';
import { BackButton } from '../assets/svgs';
import { navigationRef } from '../state/navigation.state';
import { HContainer } from './common-components';

const CHeaderContainer = StyledWithEhs(
  styled.View<SThemeType>`
    flex-direction: row;

    height: 40px;
    width: 100%;
    background-color: ${p => p.stheme.header_background};
  `,
  STheme,
);

const Left = styled(HContainer)`
  justify-content: flex-start;
  width: 33%;
`;
const Center = styled(HContainer)`
  width: 34%;
`;
const Right = styled(HContainer)`
  justify-content: flex-end;
  width: 33%;
`;

export function CHeader() {
  return (
    <CHeaderContainer>
      <Left>
        <BackButton onClick={() => navigationRef.current?.goBack()} />
      </Left>
      <Center></Center>
      <Right></Right>
    </CHeaderContainer>
  );
}
