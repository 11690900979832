import React from 'react';
import styled from 'styled-components/native';
import { GameState } from '../../state/game.state';
import { CLabel, CModal, CTitle, MarginHM, MarginHS } from '../../components';

const TargetImage = styled.Image`
  width: 200px;
  height: 400px;
`;

export function RoleRevealModal({ game }: { game: GameState }) {
  return (
    <CModal show={game.is_role_reveal_phase}>
      <CTitle>Role Reveal!</CTitle>
      <MarginHM />
      {!game.role && <CLabel>Waiting for role...</CLabel>}
      {game.role && (
        <>
          <CLabel text={`Your role is: ${game.role}`} />
          <MarginHS />
          <CLabel text={`Target: ${game.target || '???'}`} />
          <MarginHM />
          <TargetImage source={{ uri: game.target_image }} />
        </>
      )}
    </CModal>
  );
}
