import React, { useEffect } from 'react';
import { useState } from 'react';
import { CLabel, HContainer, CPageContainer, VContainer } from '../components';
import { CLabelInput } from '../components/CLabelInput';
import { MarginHL, MarginHS, MarginWS } from '../components/layout';
import { CButton } from '../components/CButton';
import { playerService } from '../services/player.service';
import { navigationRef } from '../state/navigation.state';
import { SGame } from '../state/game.state';
import { IGame } from '../models/game.model';

export function GameList(props: { playerName: string }) {
  const [mode, setMode] = useState('public');
  const [games, setGames] = useState([]);
  useEffect(() => {
    playerService
      .listPublicGames()
      .then(response => setGames(response.payload));
  }, []);

  const hasName = Boolean(props.playerName);
  const isPrivate = mode === 'private';

  return (
    <CPageContainer>
      <HContainer>
        <CLabel text="Game mode:" />
        <MarginWS />
        <CLabel
          variant={isPrivate ? 'main' : 'highlight'}
          text={isPrivate ? 'private' : 'public'}
        />
      </HContainer>
      <MarginHS />
      <CButton
        size="l"
        text={'Change mode'}
        onClick={async () => setMode(mode === 'private' ? 'public' : 'private')}
        disabled={!hasName}
      />

      <MarginHL />
      <ChooseGame playerName={props.playerName} games={games} mode={mode} />
    </CPageContainer>
  );
}

function ChooseGame(props: {
  playerName: string;
  games: IGame[];
  mode: string;
}) {
  const [gameId, setGameId] = useState('');

  const hasName = Boolean(props.playerName);

  if (props.mode === 'private') {
    return (
      <VContainer>
        <CLabelInput label="Game ID" onChange={setGameId} />
        <MarginHS />
        <CButton
          size="l"
          disabled={!hasName}
          text="Join"
          onClick={async () => {
            localStorage.setItem('player_name', props.playerName);
            const res = await playerService.joinGame(gameId, props.playerName);
            if (res.success) {
              SGame.fire(res?.payload);
              navigationRef.current?.navigate('waitingRoom', 0);
            }
          }}
        />
      </VContainer>
    );
  }

  return (
    <VContainer>
      {props.games.map((game: IGame) => (
        <>
          <HContainer key={game.id}>
            <CLabel fixedWidth={150} text={game.name} />
            <MarginWS />
            <CButton
              disabled={!hasName}
              text="Join"
              size="m"
              onClick={async () => {
                localStorage.setItem('player_name', props.playerName);
                const res = await playerService.joinGame(
                  game.id,
                  props.playerName,
                );
                if (res.success) {
                  SGame.fire(res?.payload);
                  navigationRef.current?.navigate('waitingRoom', 0);
                }
              }}
            />
          </HContainer>
          <MarginHS />
        </>
      ))}
    </VContainer>
  );
}
