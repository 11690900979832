import { EhState } from '@horos/eh';
import { IGame, PlayerRole } from '../models/game.model';

export type GameState = IGame & {
  role?: PlayerRole;
  player_id_turn?: string;
  guessing_identifier?: string;

  target?: string;
  target_image?: string;
  is_voting_phase: boolean;
  is_finished: boolean;
  imposter_won: boolean;
  is_guess: boolean;
  is_role_reveal_phase: boolean;
  votes: Record<string, string>;
};

const initialGame: () => GameState = () => ({
  id: '',
  categoryId: '',
  is_ready: false,
  is_guess: false,
  is_started: false,
  is_finished: false,
  imposter_won: false,
  name: '',
  player: {
    id: '',
    name: '',
    words: [],
  },
  players: [],
  round: 0,
  is_role_reveal_phase: true,
  is_voting_phase: false,
  votes: {},
});

export const SGame = EhState.fromInitialState(initialGame());

export async function resetGameState() {
  await SGame.fire(initialGame());
}
