import React, { useState } from 'react';
import { useEhState } from '@horos/eh-react-hooks';
import { SGame } from '../../state/game.state';
import {
  MarginHL,
  MarginHM,
  MarginWM,
  MarginWS,
} from '../../components/layout';
import { HContainer, CLabel, CPage } from '../../components';
import { CLabelInput } from '../../components/CLabelInput';
import { CButton } from '../../components/CButton';
import styled from 'styled-components/native';
import { playerService } from '../../services/player.service';
import { RoleRevealModal } from './RoleRevealModal';
import { VoteModal } from './VoteModal';
import { SDimensions } from '../../state/dimensions.state';
import { PlayersTable } from './PlayersTable';

export function Game() {
  const game = useEhState(SGame);
  const { dims } = useEhState(SDimensions);

  const [word, setWord] = useState('');

  return (
    <CPage title="Football Imposter!">
      <MarginHL />
      <HContainer>
        <CLabel text={`Game: ${game.name || game.id}`} />
        <MarginWM />
        <CLabel>Round: {game.round}</CLabel>
        <MarginWM />
        <CLabel>You are: {game.role || 'waiting for role...'}</CLabel>
        <MarginWM />
        <CLabel> Target: {game.target}</CLabel>
      </HContainer>

      <MarginHL />

      {/* gonna make a fake table here */}
      <PlayersTable dims={dims} game={game} />

      {/* <VContainer>
        {game.players.map(p => (
          <HContainer>
            <Text style={getCurrentPlayerTextStyle(p, game.player_id_turn)}>
              {p.name} {p.id === game.player.id ? ' (You)' : ''}
            </Text>
            <MarginWS />
            <Text>Words:</Text>
            <Text style={{ color: 'blue' }}>
              {(p.id === game.player.id ? game.player?.words : p.words)?.join(
                ', ',
              )}
            </Text>
          </HContainer>
        ))}
      </VContainer> */}

      <MarginHM />

      <SubmitWordContainer>
        <CLabelInput label="Your word" value={word} onChange={setWord} />
        <MarginWS />
        <CButton
          text="submit"
          size="m"
          disabled={game.player_id_turn !== game.player.id}
          onClick={() => {
            playerService.submitWord(word);
            setWord('');
          }}
        />
      </SubmitWordContainer>

      {/* <VContainer>
        <Text>Chat</Text>
      </VContainer> */}
      <RoleRevealModal game={game} />
      <VoteModal game={game} />
    </CPage>
  );
}

const SubmitWordContainer = styled.View`
  flex-direction: row;
  align-items: flex-end;
`;
