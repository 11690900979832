import React from 'react';
import { HContainer } from './common-components';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import {
  ITheme,
  Size,
  SizeVariantProp,
  Variant,
  getColor,
  getTextColor,
  getTextSize,
} from '../style';
import { STheme, SThemeType } from '../state/theme.state';
import { useEhState } from '@horos/eh-react-hooks';

export function CButton({
  onClick = () => {},
  text = 'action',
  variant = 'main',
  size = 'l',
  disabled,
}: {
  onClick?: () => void;
  text?: string;
  variant?: 'main' | 'secondary';
  size?: 'l' | 'm' | 's';
  disabled?: boolean;
}) {
  const { stheme } = useEhState(STheme);

  return (
    <Pressable
      pointerEvents={disabled ? 'none' : 'auto'}
      onPress={() => !disabled && onClick()}>
      <ButtonContainer
        size={size}
        variant={disabled ? 'disabled' : variant}
        stheme={stheme}>
        <HContainer>
          {text && (
            <ButtonText size={size} variant={variant} stheme={stheme}>
              {text}
            </ButtonText>
          )}
        </HContainer>
      </ButtonContainer>
    </Pressable>
  );
}

const getWidth = (s: Size = 'm') => {
  switch (s) {
    case 'l':
      return 150;
    case 'm':
      return 80;
  }
  // s
  return 40;
};

const getHeight = (s: Size = 'm') => {
  switch (s) {
    case 'l':
      return 50;
    case 'm':
      return 35;
  }
  // s
  return 25;
};

const getBackground = (v: Variant = 'main', theme: ITheme) => {
  let choice: keyof ITheme = 'button_background_primary';

  switch (v) {
    case 'disabled':
      choice = 'widget_background';
      break;
    case 'highlight':
      choice = 'accent';
      break;
    case 'secondary':
      choice = 'button_background_secondary';
      break;
  }

  return getColor(choice, theme);
};

const getBorder = (v: Variant = 'main', theme: ITheme) => {
  let choice: keyof ITheme = 'button_border_primary';

  switch (v) {
    case 'disabled':
      choice = 'widget_background';
      break;
    case 'highlight':
      choice = 'accent';
      break;
    case 'secondary':
      choice = 'button_border_primary';
      break;
  }

  return getColor(choice, theme);
};

const ButtonContainer = styled.View<SizeVariantProp & SThemeType>`
  width: ${(p: SizeVariantProp) => getWidth(p.size)}px;
  height: ${(p: SizeVariantProp) => getHeight(p.size)}px;

  background-color: ${p => getBackground(p.variant, p.stheme)};
  border-width: ${p => (p.variant === 'main' ? 0 : 3)}px;
  border-color: ${p => getBorder(p.variant, p.stheme)};

  align-items: center;
  justify-content: center;

  border-radius: 10px;
`;

const ButtonText = styled.Text<SizeVariantProp & SThemeType>`
  size: ${p => getTextSize(p.size)}px;
  color: ${p => getTextColor(p.variant, p.stheme)};
`;
