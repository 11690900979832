import React, { useEffect } from 'react';
import { useState } from 'react';
import { CLabelInput } from '../components/CLabelInput';
import { MarginHL, MarginHS } from '../components/layout';

import { GameList } from './GameList';
import { CPage } from '../components/CPage';

export function Join() {
  const [playerName, setPlayerName] = useState('');

  useEffect(() => {
    const storedName = localStorage.getItem('player_name');
    if (storedName) {
      setPlayerName(storedName);
    }
  }, []);

  return (
    <CPage title="Join Game">
      <MarginHL />
      <CLabelInput
        label="Your name"
        onChange={setPlayerName}
        value={playerName}
      />
      <MarginHS />
      <GameList playerName={playerName} />
    </CPage>
  );
}
