import React from 'react';
import { useState } from 'react';
import { CLabelInput } from '../components/CLabelInput';
import { MarginHL, MarginHM, MarginHS } from '../components/layout';
import { CButton } from '../components/CButton';
import { playerService } from '../services/player.service';
import { navigationRef } from '../state/navigation.state';
import { SGame } from '../state/game.state';
import { CPage } from '../components/CPage';

export function Create() {
  const [categoryName, setCategory] = useState('football');
  const [playerName, setPlayerName] = useState('');

  return (
    <CPage title="Create Game">
      <MarginHL />
      <CLabelInput label="Your name" onChange={setPlayerName} />
      <MarginHS />
      <CLabelInput
        label="Category"
        onChange={setCategory}
        value={categoryName}
      />
      <MarginHM />
      <CButton
        text="Create Game"
        onClick={async () => {
          const res = await playerService.createGame(categoryName, playerName);
          if (res.success) {
            SGame.fire(res?.payload);
            navigationRef.current?.navigate('waitingRoom', 0);
          }
        }}
      />
    </CPage>
  );
}
