import { ITheme } from './theme-models.style';

// dark theme

export const DeepTwilight: ITheme = {
  container_background: '#282c34', // Dark Charcoal
  header_background: '#21252b', // Even Darker Charcoal

  widget_background: '#3a3f4b', // Dark Grey

  text_primary: '#abb2bf', // Light Grey
  text_secondary: '#e5e9f0', // Very Light Grey
  text_trietary: '#5c6370', // Dark Grey

  button_background_primary: '#8a2be2', // Darker Purple
  button_background_secondary: '#4b5263', // Dark Slate Grey

  button_border_primary: '#7b68ee', // Deep Purple
  button_border_secondary: '#646f7e', // Slate Grey

  button_text_primary: '#ffffff', // White for contrast on darker purple
  button_text_secondary: '#e5e9f0', // Very Light Grey for readability

  accent: '#9932cc', // Dark Orchid

  success: '#98c379', // Pale Green
  warning: '#e5c07b', // Sandy
  error: '#e06c75', // Soft Red
};

export const CrispMorning: ITheme = {
  container_background: '#ffffff', // Pure White
  header_background: '#7b51a2', // Light Grey

  widget_background: '#f4f4f8', // Light Grey

  text_primary: '#1d1d1f', // Nearly Black
  text_secondary: '#686868', // Medium Grey
  text_trietary: '#a8a8a8', // Light Grey

  button_background_primary: '#bba0d0', // Light Purple
  button_background_secondary: '#e6e6ea', // Very Light Grey

  button_border_primary: '#9874ac', // Muted Purple
  button_border_secondary: '#cfcfd5', // Light Grey

  button_text_primary: '#1d1d1f', // Nearly Black for contrast on lighter purple
  button_text_secondary: '#1d1d1f', // Nearly Black for readability

  accent: '#8257e5', // Vivid Violet

  success: '#4caf50', // Medium Green
  warning: '#ffeb3b', // Yellow
  error: '#f44336', // Red
};

export const MysteriousNight: ITheme = {
  container_background: '#1c1c1e', // Very Dark Grey
  header_background: '#2a2a2e', // Darker Grey

  widget_background: '#3a3a3e', // Dark Grey

  text_primary: '#e0e0e0', // Light Grey
  text_secondary: '#c0c0c0', // Medium Grey
  text_trietary: '#a0a0a0', // Dark Grey

  button_background_primary: '#4b0082', // Deep Purple (Indigo)
  button_background_secondary: '#2a2a2e', // Darker Grey

  button_border_primary: '#800080', // Pure Purple
  button_border_secondary: '#444448', // Slate Grey

  button_text_primary: '#ffffff', // White for contrast on dark colors
  button_text_secondary: '#d0d0d0', // Light Grey for readability

  accent: '#9932cc', // Dark Orchid

  success: '#32cd32', // Lime Green
  warning: '#ff8c00', // Dark Orange
  error: '#dc143c', // Crimson
};

export const LightTheme: ITheme = CrispMorning;
export const DarkTheme: ITheme = DeepTwilight;
export const LogoTheme: ITheme = MysteriousNight;
export const DefaultTheme: ITheme = LogoTheme;
