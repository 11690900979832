import styled from 'styled-components/native';
import { STheme, SThemeType } from '../state/theme.state';
import { SDimensions, SDimensionsType } from '../state/dimensions.state';
import { StyledWithEhs } from './styledWithEh';

export const GenPressable = styled.Pressable`
  width: 100%;
  height: 100%;
`;

export const CPageContainer = StyledWithEhs(
  styled.SafeAreaView<SThemeType & SDimensionsType>`
    width: ${p => p.dims.pw(100)};
    height: ${p => p.dims.ph(100)};
    align-items: center;
    background-color: ${p => p.stheme.container_background};
    /* background-color: pink; */

    overflow: hidden;
  `,
  STheme,
  SDimensions,
);

export const VContainer = styled.View`
  align-items: center;
`;

export const HContainer = styled.View`
  flex-direction: row;
  justify-content: center;
`;

export const CBgImgVContainer = styled.ImageBackground`
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const CBgImgHContainer = styled.ImageBackground`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const CTitle = StyledWithEhs(
  styled.Text<SThemeType>`
    font-size: 24px;
    color: ${p => p.stheme.text_primary};
  `,
  STheme,
);

export const CTitleSecondary = StyledWithEhs(
  styled.Text<SThemeType>`
    font-size: 20px;
    color: ${p => p.stheme.text_secondary};
  `,
  STheme,
);

export const CInput = StyledWithEhs(
  styled.TextInput<SThemeType>`
    width: 180px;
    height: 35px;

    background-color: ${p => p.stheme.widget_background};
    color: ${p => p.stheme.text_primary};

    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
  `,
  STheme,
);

export const CDropdown = styled.TextInput<SThemeType>`
  width: 80px;
  height: 35px;
  border-color: blue;
`;
