import { useEhState } from '@horos/eh-react-hooks';
import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { SDimensions, SDimensionsType } from '../state/dimensions.state';
import styled from 'styled-components/native';
import { StyledWithEhs } from './styledWithEh';
import { STheme, SThemeType } from '../state/theme.state';

export function CModal({
  show,
  children,
}: {
  show: boolean;
  children: React.ReactNode;
}) {
  const { dims } = useEhState(SDimensions);

  const backgroundOpacityAnim = useRef(new Animated.Value(0)).current;
  const moveAnim = useRef(new Animated.Value(2500)).current;

  useEffect(() => {
    (show ? onShow : onHide)(backgroundOpacityAnim, moveAnim);
  }, [show, backgroundOpacityAnim, moveAnim]);

  return (
    <Animated.View
      style={{
        opacity: backgroundOpacityAnim,
        transform: [{ translateX: moveAnim }],
        position: 'absolute',
      }}>
      <ModalBackground dims={dims}>
        <RevealModal>{children}</RevealModal>
      </ModalBackground>
    </Animated.View>
  );
}

const ModalBackground = styled.SafeAreaView<SDimensionsType>`
  width: ${p => p.dims.pw(100)};
  height: ${p => p.dims.ph(100)};

  background-color: black;
  /* top: 2500px; */
  /* padding: 5%; */

  align-items: center;
  justify-content: center;
`;

const RevealModal = StyledWithEhs(
  styled.View<SThemeType & SDimensionsType>`
    width: 90%;
    height: 90%;

    /* max-width: 400px; */

    background-color: ${p => p.stheme.widget_background};

    padding: 20px;

    align-items: center;
  `,
  STheme,
);

const onShow = (
  backgroundOpacityAnim: Animated.Value,
  moveAnim: Animated.Value,
) => {
  // backgroundOpacityAnim.setValue()
  Animated.timing(backgroundOpacityAnim, {
    toValue: 1,
    duration: 500,
    useNativeDriver: true,
  }).start();

  Animated.timing(moveAnim, {
    toValue: 0,
    duration: 500,
    useNativeDriver: true,
    delay: 500,
  }).start();
};

const onHide = (
  backgroundOpacityAnim: Animated.Value,
  moveAnim: Animated.Value,
) => {
  // backgroundOpacityAnim.setValue()
  Animated.timing(backgroundOpacityAnim, {
    toValue: 0,
    duration: 500,
    useNativeDriver: true,
    delay: 500,
  }).start();

  Animated.timing(moveAnim, {
    toValue: 4000,
    duration: 500,
    useNativeDriver: true,
  }).start();
};
