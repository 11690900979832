import React from 'react';
import { Button, SafeAreaView, Text, TextInput, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { Home } from './pages/Home';
import { navigationRef } from './state/navigation.state';
import { Game } from './pages/Game';
import { Join } from './pages/Join';
import { WaitingRoom } from './pages/WaitingRoom';
import { Create } from './pages/Create';
import { useEhState } from '@horos/eh-react-hooks';
import { STheme } from './state/theme.state';

import './services/theme.service';

const Stack = createStackNavigator();

export default function App() {
  const { stheme } = useEhState(STheme);

  // // jump to app page here
  // useEffect(() => {
  //   async function x() {
  //     const res = await playerService.joinGame('123', 'dugi');
  //     if (res.success) {
  //       SGame.fire(res?.payload);
  //       navigationRef.current?.navigate('waitingRoom', 0);
  //     }
  //   }

  //   x();
  // }, []);

  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator
        screenOptions={{
          cardStyle: { flex: 1, backgroundColor: stheme.container_background },
          headerShown: false,
        }}
        initialRouteName="home">
        <Stack.Screen name="home" component={Home} />
        <Stack.Screen name="game" component={Game} />
        <Stack.Screen name="join" component={Join} />
        <Stack.Screen name="create" component={Create} />
        <Stack.Screen name="waitingRoom" component={WaitingRoom} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export function App2() {
  return (
    <SafeAreaView>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ width: 400 }}>
          <Text>Code changes are reflected both on web & android!!</Text>
          <Text>Your name:</Text>
          <TextInput style={{ borderColor: 'lightgray', borderWidth: 1 }} />
          <Button title="join game" />
          <Button title="create game" />
        </View>
      </View>
    </SafeAreaView>
  );
}
