import React from 'react';
import { View } from 'react-native';
import { MarginHS } from './layout';
import { CLabel } from './CLabel';
import { CInput } from './common-components';

export function CLabelInput({
  label,
  value,
  onChange,
}: {
  label: string;
  value?: string;
  onChange: (text: string) => void;
}) {
  return (
    <View>
      <CLabel>{label}</CLabel>
      <MarginHS />
      <CInput onChangeText={onChange} value={value} />
    </View>
  );
}
