import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import { GameState } from '../../state/game.state';
import {
  CLabel,
  CModal,
  CTitle,
  MarginHL,
  MarginHM,
  MarginHS,
  MarginWXS,
  VContainer,
} from '../../components';
import { CButton } from '../../components/CButton';
import { playerService } from '../../services/player.service';
import { PlayersTable } from './PlayersTable';
import { useEhState } from '@horos/eh-react-hooks';
import { SDimensions } from '../../state/dimensions.state';
import { STheme, SThemeType } from '../../state/theme.state';
import { IGame } from '../../models';
import { ITheme } from '../../style';

export function VoteModal({ game }: { game: GameState }) {
  const [voted, submitVote] = useState(false);
  const { dims } = useEhState(SDimensions);

  const [guessLines, setGuessLines] = useState<string[]>([]);

  useEffect(() => {
    if (!game.is_voting_phase) {
      submitVote(false);
    }
  }, [game]);

  const votes = useMemo(() => Object.entries(game.votes), [game.votes]);
  const votingLines = useMemo(() => {
    const lines = votes.map(([voter, votee]) =>
      getVotingText(voter, votee, game),
    );
    //     if (votes.length === game.players.length + 1) {

    // }

    if (guessLines.length > 0) {
      lines.push(...guessLines);
    }
    return lines;
  }, [votes, guessLines, game]);

  useEffect(() => {
    if (game.is_guess) {
      if (game.guessing_identifier === game.player.id) {
        setGuessLines(['$$error They are on to you! time to guess...']);
      } else {
        setGuessLines([
          '$$success You found the impostor!!',
          'But they can still guess the target...',
        ]);
      }
    }
  }, [game]);

  return (
    <CModal show={game.is_voting_phase || game.is_guess}>
      <CTitle>Vote - who is the imposter?</CTitle>
      <MarginHL />
      <PlayersTable dims={dims} game={game} />
      <MarginHL />
      <FakeTerminal lines={votingLines} />
      <MarginHM />
      {!voted && (
        <VContainer>
          {game.players.map(player => {
            return (
              player.id !== game.player.id && (
                <>
                  <CButton
                    size="l"
                    text={`${player.name}`}
                    onClick={() => {
                      submitVote(true);
                      playerService.vote(player.id);
                    }}
                  />
                  <MarginHS />
                </>
              )
            );
          })}
          <CButton
            text={'Pass...'}
            size="l"
            variant="secondary"
            onClick={() => {
              submitVote(true);
              playerService.vote('');
            }}
          />
        </VContainer>
      )}
    </CModal>
  );
}

const CaretBase = styled.View`
  height: 10px;
  width: 3px;
  margin-top: 3px;
`;

const LeftContainer = styled.View`
  flex-direction: row;
  align-items: center;

  /* background-color: fuchsia; */
`;

const FakeFillingText = ({
  text,
  isLast,
}: {
  text: string;
  isLast: boolean;
}) => {
  const [realText, setRealText] = useState('');
  const [color, setColor] = useState<keyof ITheme>('button_text_primary');

  useEffect(() => {
    let textForTerm = text;
    if (text.startsWith('$$')) {
      const split = text.split(' ');
      const colorCode = split[0] as keyof ITheme;
      setColor(colorCode);
      textForTerm = split.slice(1).join('');
    }

    let current = '';
    let i = 0;
    const interval = setInterval(() => {
      current += textForTerm[i++];
      setRealText(current);
      if (current === textForTerm) {
        clearInterval(interval);
      }
    }, 100);
  }, [text]);

  return (
    <LeftContainer>
      <CLabel size="s" themeColor={color} text={realText} />
      <MarginWXS />
      {isLast && <FakeBlinkingCaret />}
    </LeftContainer>
  );
};

const FakeBlinkingCaret = () => {
  const [backgroundColor, setBackgroundColor] = useState('black');

  useEffect(() => {
    let bg = 'black';
    const interval = setInterval(() => {
      setBackgroundColor(bg);
      bg = bg === 'black' ? 'white' : 'black';
    }, 700);

    return () => clearInterval(interval);
  }, []);

  return <CaretBase style={{ backgroundColor }} />;
};

function FakeTerminal({ lines }: { lines?: string[] }) {
  const { stheme } = useEhState(STheme);

  return (
    <FakeTerminalContainer stheme={stheme}>
      {lines?.map((l, idx) => (
        <FakeFillingText key={idx} text={l} isLast={idx === lines.length - 1} />
      ))}
      {!lines || (lines.length === 0 && <FakeBlinkingCaret />)}
    </FakeTerminalContainer>
  );
}

const FakeTerminalContainer = styled.View<SThemeType>`
  background-color: black;

  width: 300px;
  min-height: 50px;

  padding: 5px;
`;

function playerNameById(
  playerId: string,
  game: IGame,
  fallback: string = '',
  before: string = '',
) {
  let s =
    (game.player.id === playerId
      ? game.player.name
      : game.players.find(p => p.id === playerId)?.name) || fallback;

  if (s !== fallback) {
    return before + s;
  }

  return s;
}

function getVotingText(voterId: string, voteeId: string, game: IGame) {
  return `${playerNameById(voterId, game)} ${playerNameById(
    voteeId,
    game,
    'passed',
    'voted ',
  )}`;
}
