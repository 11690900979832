import {
  IEvent,
  IGameEnd,
  IGuessTarget,
  INewVote,
  INewWord,
  IPlayerJoined,
  IPlayerLeft,
  IRoleReveal,
  IRoundUpdate,
  ITurnUpdate,
} from '../models/game.model';
import { SGame } from '../state/game.state';
import { IAPI } from './api';
// import { mockAPI } from './mock-api';
// const api: IAPI = mockAPI;

import { realAPI } from './real-api';
const api: IAPI = realAPI;

class PlayerService {
  async start() {
    await api.startGame();
  }

  async listPublicGames(): Promise<any> {
    const response = await api.listGames();
    return response;
  }

  async joinGame(id: string, name: string): Promise<any> {
    const response = await api.joinGame(id, name);
    if (response.success) {
      api.getEvents(this.handleEvent.bind(this));
    }
    return response;
  }

  async createGame(category: string, name: string): Promise<any> {
    const response = await api.createGame(category, name);
    if (response.success) {
      api.getEvents(this.handleEvent.bind(this));
    }
    return response;
  }

  async submitWord(word: string): Promise<void> {
    await api.submitWord(word);
  }

  async vote(target: string): Promise<void> {
    await api.votePlayer(target);
  }

  private handleEvent(event: IEvent) {
    switch (event.type) {
      case 'player-joined':
        this.handlePlayerJoin(event as IPlayerJoined);
        return;
      case 'player-left':
        this.handlePlayerLeft(event as IPlayerLeft);
        return;
      case 'game-ready':
        SGame.fire({ is_ready: true });
        return;
      case 'game-started':
        SGame.fire({ is_started: true });
        return;
      case 'role-reveal':
        const { type, ...roleReveal } = event as IRoleReveal;
        SGame.fire({ ...roleReveal, is_role_reveal_phase: true });
        return;
      case 'start-round':
        SGame.fire({
          round: (event as IRoundUpdate).round,
          is_voting_phase: false,
          is_role_reveal_phase: false,
          votes: {},
        });
        return;
      case 'turn':
        SGame.fire({ player_id_turn: (event as ITurnUpdate).player_id });
        return;
      case 'new-word':
        this.handleNewWord(event as INewWord);
        return;
      case 'end-round':
        SGame.fire({
          is_voting_phase: true,
        });
        return;
      case 'end-game':
        SGame.fire({
          is_finished: true,
          imposter_won: (event as IGameEnd).imposter_won,
        });
        return;
      case 'guess-target':
        SGame.fire({
          is_guess: true,
          guessing_identifier: (event as IGuessTarget).player_id,
        });
        return;
      case 'vote':
        const { player_id: playerId, target_id: targetId } = event as INewVote;
        SGame.fire({ votes: { ...SGame.state.votes, [playerId]: targetId } });
        return;
      case 'new-message':
        return;
    }
  }

  private handlePlayerJoin(event: IPlayerJoined) {
    SGame.fire({ players: [...SGame.state.players, { ...event, words: [] }] });
  }

  private handlePlayerLeft(event: IPlayerLeft) {
    SGame.fire({
      players: [
        ...SGame.state.players.filter(player => player.id !== event.id),
      ],
    });
  }

  private handleNewWord(event: INewWord) {
    if (event.player_id === SGame.state.player.id) {
      SGame.fire({
        player: {
          ...SGame.state.player,
          words: SGame.state.player.words.concat([event.word]),
        },
      });

      return;
    }

    SGame.fire({
      players: SGame.state.players.map(p => {
        if (p.id !== event.player_id) return p;
        if (!p.words) {
          p.words = [];
        }

        return {
          ...p,
          words: p.words.concat([event.word]),
        };
      }),
    });
  }
}

export const playerService = new PlayerService();
