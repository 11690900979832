import React from 'react';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { VContainer, GenPressable } from './common-components';
import { MarginHXS } from './layout';
import { CLabel } from './CLabel';

export function CIconButton({
  icon,
  text,
  onClick,
}: {
  icon: IconDefinition;
  text?: string;
  onClick?: () => void;
}) {
  return (
    <VContainer>
      <GenPressable onPress={onClick}>
        <BorderedView>
          <FontAwesomeIcon
            size={70}
            icon={icon}
            style={{ width: '100%', height: '100%' }}
          />
        </BorderedView>
      </GenPressable>
      {text && <MarginHXS />}
      {text && <CLabel>{text}</CLabel>}
    </VContainer>
  );
}

const BorderedView = styled.View`
  width: 100px;
  height: 100px;

  border-radius: 10px;
  border: orange solid 5px;

  padding: 10px;
`;

export type TCategoryButtonProps = {
  navigateTo: string;
  image: string;
};
