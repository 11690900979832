import { ITheme, Size, Variant } from './theme-models.style';

export const getTextSize = (s: Size = 'm') => {
  switch (s) {
    case 'l':
      return 28;
    case 'm':
      return 16;
  }
  // s
  return 13;
};

export function getColor(color: keyof ITheme, theme: ITheme): string {
  return theme[color];
}

export const getTextColor = (v: Variant = 'main', theme: ITheme) => {
  let colorKey: keyof ITheme = 'text_primary';
  switch (v) {
    case 'secondary':
      colorKey = 'text_secondary';
      break;
    case 'highlight':
      colorKey = 'accent';
      break;
  }
  return getColor(colorKey, theme);
};
