import styled from 'styled-components/native';

export const MarginHL = styled.View`
  height: 30px;
`;

export const MarginHM = styled.View`
  height: 20px;
`;

export const MarginHS = styled.View`
  height: 10px;
`;

export const MarginHXS = styled.View`
  height: 5px;
`;

export const MarginWL = styled.View`
  width: 30px;
`;

export const MarginWM = styled.View`
  width: 20px;
`;

export const MarginWS = styled.View`
  width: 10px;
`;

export const MarginWXS = styled.View`
  width: 2px;
`;
