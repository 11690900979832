import { SDeviceTheme } from '../state/theme.state';

console.log("I'm running in web!");

const prefersDarkMode =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

SDeviceTheme.fire({ deviceTheme: prefersDarkMode ? 'dark' : 'light' });

if (prefersDarkMode) {
  // Use dark theme
} else {
  // Use light theme
}

window
  .matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', event => {
    if (event.matches) {
      // Now in dark mode
      SDeviceTheme.fire({ deviceTheme: 'dark' });
    } else {
      // Now in light mode
      SDeviceTheme.fire({ deviceTheme: 'light' });
    }
  });
