import React, { useEffect } from 'react';
import { CLabel } from '../components';
import { useEhState } from '@horos/eh-react-hooks';
import { SGame } from '../state/game.state';
import { MarginHL, MarginHS, MarginHXS } from '../components/layout';
import { navigationRef } from '../state/navigation.state';
import { CButton } from '../components/CButton';
import { playerService } from '../services/player.service';
import { CPage } from '../components/CPage';

export function WaitingRoom() {
  const game = useEhState(SGame);

  useEffect(() => {
    if (game.is_started) {
      navigationRef.current?.navigate('game', 0);
    }
  }, [game.is_started]);

  return (
    <CPage title="Waiting Room">
      <MarginHL />
      <CLabel text={`Waiting for game ${game.name} to start`} />
      <MarginHS />
      <CLabel
        themeColor={game.is_ready ? 'success' : 'error'}
        text={
          game.is_ready ? 'The game is ready to start!' : 'Waiting for players'
        }
      />

      <MarginHS />
      <CLabel text="Players:" />
      <MarginHXS />
      <CLabel text={game.players.map(p => p.name).join(', ')} />
      <MarginHS />
      {game.is_ready && (
        <CButton text="Start Game" onClick={() => playerService.start()} />
      )}
    </CPage>
  );
}
