import { useEhState } from '@horos/eh-react-hooks';
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { STheme, SThemeType } from '../../state/theme.state';
import styled from 'styled-components/native';

const SVGContainer = styled.Pressable<{ bg: string }>`
  width: 40px;
  height: 100%;

  align-items: center;
  justify-content: center;

  background-color: ${p => p.bg};
`;

export function BackButton({
  onClick,
  bg,
  bghover,
  bgactive,
  stroke,
  strokehover,
  strokeactive,
}: {
  onClick?: () => void;
  bg?: string;
  bghover?: string;
  bgactive?: string;
  stroke?: string;
  strokehover?: string;
  strokeactive?: string;
}) {
  return (
    <SVGContainer bg={bg || 'white'} onPress={onClick}>
      <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <Path
          d="M15 19L8 12L15 5"
          stroke={stroke || 'black'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </SVGContainer>
  );
}
