export interface IProtocolResponse {
  success: boolean;
  error?: string;
}

export interface IStandardResponse<T> extends IProtocolResponse {
  payload: T;
}

export type StdRes<T> = Promise<IStandardResponse<T>>;

export function success<T>(payload: T): StdRes<T> {
  return new Promise<IStandardResponse<T>>(resolve => {
    resolve({
      success: true,
      payload,
    });
  });
}
