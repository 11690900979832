export class WebSocketClient {
  private socket: WebSocket;

  constructor(url: string) {
    this.socket = new WebSocket(url);

    // Default handlers for basic WebSocket events
    this.socket.onopen = () => console.log('WebSocket is open now.');
    this.socket.onclose = () => console.log('WebSocket is closed now.');
    this.socket.onerror = error => console.error('WebSocket Error: ', error);
  }

  public onOpen(callback: (event: Event) => void): void {
    this.socket.onopen = callback;
  }

  public onMessage(callback: (event: MessageEvent) => void): void {
    this.socket.onmessage = callback;
  }

  public onError(callback: (event: Event) => void): void {
    this.socket.onerror = callback;
  }

  public onClose(callback: (event: CloseEvent) => void): void {
    this.socket.onclose = callback;
  }

  public send(message: string): void {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Message not sent:', message);
    }
  }

  public close(): void {
    this.socket.close();
  }
}
