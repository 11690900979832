import styled from 'styled-components/native';
import { SDimensionsType } from '../state/dimensions.state';
import { useEhState } from '@horos/eh-react-hooks';
import { STheme } from '../state/theme.state';
import React, { useMemo } from 'react';

export const Table = styled.View<SDimensionsType>`
  flex-direction: row;
  /* width: ${p => p.dims.pw(100)}; */
  width: 100%;
  max-width: 700px;
`;

export const ColumnContainer = styled.View<{
  widthPercentage: number;
  background: string;
}>`
  width: ${p => p.widthPercentage}%;
  background-color: ${p => p.background};
`;

export function Column({
  widthPercentage,
  columnIdx,
  children,
}: {
  widthPercentage: number;
  columnIdx: number;
  children: React.ReactNode;
}) {
  const { stheme } = useEhState(STheme);

  const background = useMemo(
    () =>
      columnIdx % 2 === 0 ? stheme.header_background : stheme.widget_background,
    [stheme, columnIdx],
  );

  return (
    <ColumnContainer background={background} widthPercentage={widthPercentage}>
      {children}
    </ColumnContainer>
  );
}

export const Cell = styled.View<{ alignStart?: boolean; cellIdx: number }>`
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: ${p => (p.alignStart ? 'flex-start' : 'center')};

  background-color: ${p => (p.cellIdx % 2 === 0 ? '#FFFFFF00' : '#FFFFFF10')};

  padding-left: 5px;
  padding-right: 5px;
`;
