import React from 'react';
import { Cell, Column, Table } from '../../components/CTable';
import { SDimensionsType } from '../../state/dimensions.state';
import { CLabel } from '../../components';
import { GameState } from '../../state/game.state';
import { IPlayer } from '../../models';

const GameRounds = [1, 2, 3, 4];

export function PlayersTable({
  dims,
  game,
}: SDimensionsType & {
  game: GameState;
}) {
  return (
    <Table dims={dims}>
      <Column columnIdx={0} widthPercentage={20}>
        <Cell cellIdx={0} alignStart>
          <CLabel bold>Players</CLabel>
        </Cell>
        {game.players.map((p, idx) => (
          <PlayerNameCell key={p.id} cellIdx={2 + idx} player={p} game={game} />
        ))}
      </Column>
      {GameRounds.map(gr => (
        <WordsColumn key={gr} columnRound={gr} game={game} />
      ))}
    </Table>
  );
}

function WordsColumn({
  columnRound,
  game,
}: {
  columnRound: number;
  game: GameState;
}) {
  return (
    <Column
      columnIdx={columnRound}
      widthPercentage={game.round === columnRound ? 35 : 15}>
      <Cell cellIdx={0}>
        <CLabel
          variant={columnRound === game.round ? 'highlight' : 'main'}
          bold>
          R{columnRound}
        </CLabel>
      </Cell>
      {game.players.map((p, idx) => (
        <PlayerWordCell
          cellIdx={2 + idx}
          key={idx}
          columnRound={columnRound}
          player={p}
          game={game}
        />
      ))}
    </Column>
  );
}

function PlayerNameCell({
  player,
  cellIdx,
  game,
}: {
  player: IPlayer;
  cellIdx: number;
  game: GameState;
}) {
  return (
    <Cell cellIdx={cellIdx}>
      <CLabel
        themeColor={
          player.id === game.player_id_turn ? 'success' : 'text_primary'
        }
        text={player.name}
      />
    </Cell>
  );
}

function PlayerWordCell({
  game,
  columnRound,
  player,
  cellIdx,
}: {
  game: GameState;
  columnRound: number;
  player: IPlayer;
  cellIdx: number;
}) {
  const words = player.id === game.player.id ? game.player.words : player.words;
  return (
    <Cell cellIdx={cellIdx}>
      <CLabel text={words ? words[columnRound - 1] : ''} />
    </Cell>
  );
}
